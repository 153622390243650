.app {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-size: cover;
  .pages {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 80px;
    .CircularProgressbar {
      width: 80px;
      height: 80px;
    }
  }
  .dateswitch {
    width: 1880px;
    height: 100px;
    position: absolute;
    left: 20px;
    top: 0px;
    padding-bottom: 10px;
    overflow: hidden;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    display: flex;
    align-items: center;
  }
  .dateswitch:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
  }
  .dateswitch div {
    font-weight: bold;
    color: white;
    font-size: 30px;
    flex: 1;
    text-align: center;
  }
  .holder {
    width: 1880px;
    height: 880px;
    position: absolute;
    top: 100px;
    left: 20px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    font-size: 30px;
    overflow: hidden;
  }
  .header {
    font-weight: normal;
    color: white;
    max-height: 62px;
  }
  .blocks {
    max-height: 76px;
    padding: 10px;
    overflow: hidden;
    display: block;
    margin-right: 15px;
    float: left;
    min-height: 1px;
    line-height: 40px;
    overflow: hidden;
  }
  .orderline {
    display: flex;
    position: relative;
  }
  .orderline .wrapper {
    transform: translateX(calc(100% + 20px));
    height: 100px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: white;
    display: flex;
    align-items: center;
    :last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  .orderline .wrapper.done {
    background-color: rgba(0, 0, 0, 0.12);
  }
  .orderline .wrapper.current {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .orderline.show .wrapper {
    animation: slide-in 0.5s forwards;
  }
  .d-flex {
    display: flex;
  }
  .firstblock {
    width: 200px;
  }
  .secondblock {
    width: 614px;
    white-space: normal;
  }
  .thirdblock {
    width: 300px;
  }
  .fourthblock {
    width: 110px;
    text-align: center;
  }
  .fifthblock {
    width: 160px;
  }
  .sixthblock {
    width: 110px;
    text-align: center;
    margin-right: 0px;
  }
  .sevenblock {
    width: 200px;
    text-align: left;
    margin-right: 0px;
  }
  .page {
    display: none;
  }
  .page.show {
    display: block;
  }
}
@keyframes slide-in {
  100% {
    transform: translateX(0);
  }
}
