body {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-color: black;
}
.shift-enter {
  opacity: 0.01;
}

.shift-enter.shift-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.shift-leave {
  opacity: 1;
}

.shift-leave.shift-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.closeSetting {
  margin-left: 15px;
  display: flex;
  align-items: center;
  .close {
    opacity: 1;
    color: red;
    width: 30px;
    height: 30px;
  }
  .close:hover {
    color: red;
    opacity: 0.7;
  }
}
.green {
  background-image: radial-gradient(at bottom left, #789c00, #027f68);
  color: white;
}
.blue {
  background-image: radial-gradient(at bottom left, #2c324e, #101b24);
  color: white;
}
